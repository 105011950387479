// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bk_k4";
export var caseStudyBackground__bgColor = "bk_kV";
export var caseStudyBackground__lineColor = "bk_kW";
export var caseStudyHead = "bk_k6";
export var caseStudyHead__imageAbsolute = "bk_k9";
export var caseStudyHead__imageWrapper = "bk_kT";
export var caseStudyProjectsSection = "bk_k5";
export var caseStudyQuote__bgRing = "bk_k1";
export var caseStudyTechSection = "bk_lb";
export var caseStudy__bgDark = "bk_kS";
export var caseStudy__bgLight = "bk_kR";